@import 'constants/theme.less';

.footer-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 26px;
  margin-right: 26px;
  fill: currentColor;
  color: #fff !important;
}

.text,
.text a {
  color: @white !important;
  text-align: left !important;
}
