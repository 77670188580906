@import 'constants/theme.less';

.footer-button-booking {
  background-color: #aa0000 !important;
  outline: none !important;
  border: none !important;

  &:hover {
    color: @black !important;
  }
}
