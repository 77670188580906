.logo {
  display: inline-block;
  padding: 0 20px 20px;
  background-color: #aa0000;
  cursor: pointer;
  @media only screen and (max-width: 584px) {
    padding: 0 10px 10px;
  }
  @media only screen and (max-width: 390px) {
    padding: 0 8px 8px;
  }
  @media only screen and (max-width: 292px) {
    padding: 0 6px 4px;
  }

  .logo-icon {
    width: 40px;
    height: 40px;
    fill: currentColor;
    color: #000 !important;
    @media only screen and (max-width: 538px) {
      width: 30px;
      height: 30px;
    }
    @media only screen and (max-width: 390px) {
      width: 26px;
      height: 26px;
    }
  }
}
