// Override antd theme
@import 'constants/theme.less';

#root-layout {
  min-height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#layout-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 64px;
  background-color: @black;
  @media only screen and (max-width: 584px) {
    padding: 0 10px !important;
  }
}

#layout-content {
  margin-top: 64px;
}

#layout-footer {
  display: flex;
  width: 100%;
  background-color: @black;
  @media only screen and (max-width: 584px) {
    padding: 14px 14px 0 !important;
  }
  @media only screen and (max-width: 390px) {
    padding: 16px !important;
  }
}

// Custom scrollbar
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

// Override antd css
.glc-modal-content {
  border-radius: 10px !important;
}

//
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.items-center {
  align-items: center;
}

.breadcrumb {
  padding: 40px 50px 20px;
}

.accent-text {
  color: #6f915f !important;
}

.primary-text {
  color: @primary-color !important;
}

.no-margin {
  margin: 0px !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.cursor-pointer {
  cursor: pointer;
}

.backgroud-default {
  background-color: @layout-body-background !important;
}

.glc-title {
  display: block;
  font-size: 2rem;
  text-align: center;
}

.glc-text {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

@media only screen and (max-width: 786px) {
  .sm-mt-20 {
    margin-top: 20px;
  }
}
