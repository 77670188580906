@import 'constants/theme.less';

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  .header-right-list {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    height: 100%;
    padding-left: 0;

    & a {
      color: @white;
    }

    .header-right-text,
    .header-right-text p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 8px 20px;
      cursor: pointer;
      z-index: 10;
      @media only screen and (max-width: 538px) {
        padding: 8px 8px;
      }
      @media only screen and (max-width: 292px) {
        font-size: 8px;
      }
      & > a {
        @media only screen and (max-width: 425px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 380px) {
          font-size: 10px;
        }
        @media only screen and (max-width: 292px) {
          font-size: 8px;
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
