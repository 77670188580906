.third-col {
  @media only screen and (max-width: 575px) {
    margin-top: -92px;
  }
  @media only screen and (max-width: 502px) {
    margin-top: -157px;
  }
  @media only screen and (max-width: 390px) {
    margin-top: -180px;
  }
}
